import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["panel"];
  connect() {
    console.log("Slide Panel Controller Connected");
  }

  toggle() {
    console.log("hehe");
    this.panelTarget.classList.toggle("hidden");
    document.body.classList.toggle("overflow-hidden");
  }
}
