// app/javascript/controllers/tabs_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tabs", "container"];

  connect() {
    // Check if there's an anchor in the URL and select the corresponding tab
    const anchorIndex = parseInt(window.location.hash.substring(1), 10);
    if (!isNaN(anchorIndex) && anchorIndex >= 0 && anchorIndex < this.tabsTargets.length) {
      this.showTab(this.tabsTargets[anchorIndex]);
    } else {
      // If no anchor found or it's invalid, show the default tab (tab 0)
      this.showTab(this.tabsTargets[this.defaultTab]);
    }

    // Add click event listeners to the tab links
    this.tabsTargets.forEach((tab) => {
      tab.addEventListener("click", this.tabClickHandler.bind(this));
    });
  }

  tabClickHandler(event) {
    event.preventDefault();
    this.showTab(event.currentTarget);
  }

  showTab(clickedTab) {
    const clickedTabIndex = parseInt(clickedTab.dataset.tabIndex);
    console.log(clickedTab.dataset.tabIndex);

    this.tabsTargets.forEach((tab, tabIndex) => {
      if (tabIndex === clickedTabIndex) {
        tab.classList.add("bg-gray-100", "text-gray-700");
        tab.setAttribute("aria-current", "page");
      } else {
        tab.classList.remove("bg-gray-100", "text-gray-700");
        tab.removeAttribute("aria-current");
      }
    });

    // Show the corresponding tab content container
    this.containerTargets.forEach((container, containerIndex) => {
      if (containerIndex === clickedTabIndex) {
        container.classList.remove("hidden");
      } else {
        container.classList.add("hidden");
      }
    });

    // Update the URL with the tab number as the anchor
    window.history.replaceState(null, null, `#${clickedTabIndex}`);
  }

  get defaultTab() {
    return this.data.get("defaultTab") || 0;
  }
}
