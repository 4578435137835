import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["count"];

  connect() {
    console.log("Cart Controller Connected");
  }

  update(event) {
    this.countTarget.innerHTML = event.detail.cart_count;
    console.log(event);
  }

  delete(event) {
    this.countTarget.innerHTML = this.countTarget.innerHTML - 1;
  }
}
