import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    console.log("Pagination controller connected...");
  }

  pageChanged(event) {
    event.preventDefault();
    this.dispatch("pageChanged", { detail: { url: event.currentTarget.href } });
  }
}
