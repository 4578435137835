// app/javascript/controllers/tabs_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const element = this.element;
    const viewportOffset = element.getBoundingClientRect();
    const { top } = viewportOffset;
    const maxHeight = window.innerHeight - top - 100;
    element.style.maxHeight = maxHeight + "px";
  }
}
