import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["items", "more", "less"];

  more() {
    this.itemsTarget.classList.toggle("hidden");
    this.lessTarget.classList.remove("hidden");
    this.lessTarget.classList.add("block");
    this.moreTarget.classList.remove("block");
    this.moreTarget.classList.add("hidden");
  }

  less() {
    this.itemsTarget.classList.toggle("hidden");
    this.lessTarget.classList.remove("block");
    this.lessTarget.classList.add("hidden");
    this.moreTarget.classList.remove("hidden");
    this.moreTarget.classList.add("block");
  }
}
