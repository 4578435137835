import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import Rails from "@rails/ujs";

Rails.start();

const application = Application.start();

// Expose application variable via Stimulus Attribute on the window object
window.Stimulus = application;

const gart_ctx = require.context("controllers/gart", true, /_controller\.js$/);
application.load(definitionsFromContext(gart_ctx));
const generic_ctx = require.context("controllers/generic", true, /_controller\.js$/);
application.load(definitionsFromContext(generic_ctx));

application.consumer = consumer;

StimulusReflex.initialize(application);
StimulusReflex.debug = process.env.RAILS_ENV === "development";
