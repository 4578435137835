import formatter from "../../../helpers/formatter";
export default class ChunkEngine {
  constructor(recorded_at, result, chunks) {
    this.recorded_at = recorded_at;
    this.startTimeLabel = formatter.formatDateOffset(recorded_at, result.start_chunk.start_time);
    this.start_sequence = result.start_chunk.sequence;
    this.end_sequence = result.end_chunk.sequence;
    this.endTimeLabel = formatter.formatDateOffset(recorded_at, result.end_chunk.end_time);
    this.chunks = chunks;
  }

  getChunk(chunk) {
    const el = document.createElement("p");
    el.textContent = chunk.text;
    el.setAttribute("data-start-time", chunk.start_time);
    el.setAttribute("data-end-time", chunk.end_time);
    return el;
  }

  trimStart() {
    const chunk = this.chunks[this.start_sequence + 1];
    this.startTimeLabel = formatter.formatDateOffset(this.recorded_at, chunk.start_time);
    this.start_sequence = this.start_sequence + 1;
    return chunk.start_time;
  }

  trimEnd() {
    const chunk = this.chunks[this.end_sequence - 1];
    this.endTimeLabel = formatter.formatDateOffset(this.recorded_at, chunk.start_time);
    this.end_sequence = this.end_sequence - 1;
    return chunk.end_time;
  }

  getPrevChunk() {
    const previousChunk = this.chunks[this.start_sequence - 1];
    const chunk = {
      node: this.getChunk(previousChunk),
      start_time: previousChunk.start_time,
    };
    this.startTimeLabel = formatter.formatDateOffset(this.recorded_at, previousChunk.start_time);
    this.start_sequence = this.start_sequence - 1;
    return chunk;
  }

  getNextChunk() {
    const nextChunk = this.chunks[this.end_sequence + 1];
    const chunk = {
      node: this.getChunk(nextChunk),
      end_time: nextChunk.end_time,
    };
    this.endTimeLabel = formatter.formatDateOffset(this.recorded_at, nextChunk.end_time);
    this.end_sequence = this.end_sequence + 1;
    return chunk;
  }

  timeUpdate() {
    console.log(this);
  }
}
