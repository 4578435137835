import { Controller } from "stimulus";
import { enter, leave } from "el-transition";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["dialog"];

  connect() {}

  show(event) {
    const params = new URLSearchParams({
      delete_url: event.params.deleteUrl,
      dom_id: event.params.domId,
      order_type: event.params.orderType,
      execute_method: event.params.executeMethod,
    }).toString();
    event.preventDefault();
    Rails.ajax({
      type: "GET",
      url: event.currentTarget.getAttribute("href"),
      dataType: "json",
      data: params,
      success: (response, status, xhr) => {
        this.element.insertAdjacentHTML("beforeend", response);
        enter(this.dialogTarget);
      },
      error: (response, status, xhr) => {
        console.log(response);
      },
    });
  }

  delete_line_item(event) {
    const order_type = event.params.orderType;
    event.preventDefault();
    Rails.ajax({
      type: "DELETE",
      url: event.target.getAttribute("href"),
      dataType: "json",
      success: (response, status, xhr) => {
        const { alert } = response;
        leave(this.dialogTarget).then(() => {
          this.element.remove();
          if (alert) {
            document.getElementById("notification").innerHTML = alert;
          }
        });
        const cart_event = new CustomEvent("delete-cart");
        window.dispatchEvent(cart_event);
      },
      error: (response, status, xhr) => {
        console.log(response);
      },
    });
  }

  delete(event) {
    event.preventDefault();
    const dom_id = event.params.domId;
    Rails.ajax({
      type: "DELETE",
      url: event.target.getAttribute("href"),
      dataType: "json",
      success: (response, status, xhr) => {
        leave(this.dialogTarget).then(() => {
          document.getElementById(dom_id).remove();
          document.getElementById("notification").innerHTML = response.alert;
        });
      },
      error: (response, status, xhr) => {
        console.log(response);
      },
    });
  }

  dismiss(event) {
    event.preventDefault();
    leave(this.dialogTarget).then(() => {
      this.dialogTarget.remove();
    });
  }
}
