import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}
  static targets = ["errorContainer"];

  async validate(event) {
    const formData = await event.detail;
    const { status, responseText } = formData[0];

    let jsonObj = JSON.parse(responseText);
    jsonObj["dom_id"] = event.target.id;
    jsonObj["modal_controller_id"] = event.target.dataset.modalControllerId;

    // Success
    if (status == 200) {
      const { redirect_to, forbidden, alert, remove, replace, dom_id, dispatch_event } = JSON.parse(responseText);

      if (dispatch_event) {
        window.dispatchEvent(new CustomEvent(dispatch_event, { detail: jsonObj }));
      }

      if (redirect_to) {
        setTimeout(function () {
          document.location.href = redirect_to;
        }, 100);
      }

      if (replace && dom_id && alert) {
        document.getElementById(dom_id).innerHTML = replace;
        document.getElementById("notification").innerHTML = alert;
      }

      if (remove && dom_id && alert) {
        document.getElementById(dom_id).remove();
        document.getElementById("notification").innerHTML = alert;
      }

      if (alert) {
        document.getElementById("notification").innerHTML = alert;
      }

      if (forbidden) {
        document.getElementById("notification").innerHTML = forbidden;
      }
    }
    const { errors } = JSON.parse(responseText);

    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      const errorMsg = this.extractError({
        errors,
        type: errorType,
      });

      if (errorMsg) {
        errorContainer.innerText = errorMsg || "";
        errorContainer.classList.remove("hidden");
        errorContainer.classList.add("active");
      } else {
        errorContainer.innerText = "";
        errorContainer.classList.add("hidden");
        errorContainer.classList.remove("active");
      }
    });
  }

  extractError({ errors, type }) {
    if (!errors || !Array.isArray(errors)) return;

    const foundError = errors.find((error) => error.type.toLowerCase() === type.toLowerCase());
    return foundError?.detail;
  }
}
