import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import AudioEngine from "./media_player/audio_engine";
import ChunkEngine from "./media_player/chunk_engine";
import("../../styles/audio.css");

export default class extends Controller {
  static targets = [
    "nativePlayer",
    "timeline",
    "timeDisplay",
    "preview",
    "volumeSlider",
    "chunks",
    "chunkStartTime",
    "chunkEndTime",
    "clients",
  ];

  connect() {
    Rails.ajax({
      type: "GET",
      url: "/fr/rtv/trxns/1020",
      dataType: "json",
      success: (data) => {
        const {
          transcription: { recorded_at, chunks, results },
        } = data;
        this.audioEngine = new AudioEngine(recorded_at, results[0]);
        this.chunkEngine = new ChunkEngine(recorded_at, results[0], chunks);
        for (let i = this.chunkEngine.start_sequence; i <= this.chunkEngine.end_sequence; i++) {
          const chunk = this.chunkEngine.getChunk(this.chunkEngine.chunks[i]);

          for (let index = 0; index < results[0].keywords.length; index++) {
            const element = results[0].keywords[index];
            const text = chunk.innerHTML.toLowerCase();
            //console.log(element.keyword);
            if (text.includes(element.keyword)) {
              const index = text.indexOf(element.keyword);
              if (index >= 0) {
                const result =
                  text.substring(0, index) +
                  "<span class='bg-blue-200 rounded px-2 py-1'>" +
                  text.substring(index, index + element.keyword.length) +
                  "</span>" +
                  text.substring(index + element.keyword.length);
                chunk.innerHTML = result;
              }
            }
          }
          this.chunksTarget.appendChild(chunk);
        }

        let clients = new Set();
        results[0].keywords.forEach((keyword) => {
          keyword.accounts.forEach((account) => {
            clients.add(account.code_client);
          });
        });

        clients.forEach((client) => {
          this.clientsTarget.innerHTML += `<p>${client}</p>`;
        });

        this.reset();
      },
    });
  }

  reset() {
    this.timeDisplayTarget.textContent = this.chunkEngine.startTimeLabel;
    this.chunkStartTimeTarget.textContent = this.chunkEngine.startTimeLabel;
    this.chunkEndTimeTarget.textContent = this.chunkEngine.endTimeLabel;
    this.nativePlayerTarget.currentTime = this.audioEngine.start_time;
    this.audioEngine.timeUpdate(this.audioEngine.start_time);
    this.nativePlayerTarget.pause();
    this.element.classList.add("paused");
    this.displayProgressPosition(0);
    this.element.classList.remove("opacity-0");
    this.element.classList.remove("pointer-events-none");
    document.getElementById("loading").classList.add("hidden");
  }

  /**
   * Time Update Event Handling
   * Fire when native player time updates
   */
  handleTimeUpdate(event) {
    // End of audio
    if (Math.trunc(this.audioEngine.getCurrentTime()) === this.audioEngine.getEndTime()) {
      this.reset();
    } else {
      this.audioEngine.timeUpdate(event.target.currentTime);
      this.chunkEngine.timeUpdate();
      this.timeDisplayTarget.textContent = this.audioEngine.getCurrentTimeFormatted();
      this.displayProgressPosition(this.audioEngine.getCurrentTime());
    }
  }

  /**
   * Toggle Play Pause Event Handling
   * Fire when Play / Pause button is clicked
   */
  togglePlayPause(event) {
    if (this.nativePlayerTarget.paused) {
      this.nativePlayerTarget.play();
      this.element.classList.remove("paused");
    } else {
      this.nativePlayerTarget.pause();
      this.element.classList.add("paused");
    }
  }

  /**
   * Set Slider Position Event Handling
   * Fire when muted button is clicked
   */
  setVolumeSliderPosition(event) {
    if (this.nativePlayerTarget.volume > 0) {
      // slider needs to be set to 0 before mute
      this.dispatchToggleMuteEvent(0);
    } else {
      // slider needs to be set to last position before unmute in percent
      this.dispatchToggleMuteEvent(this.audioEngine.getVolume() * 100);
    }
  }

  // Set Volume
  setVolume(event) {
    const volume = event.detail.volume;
    this.setVolumeLevel(volume);
    this.nativePlayerTarget.muted = volume === 0;
    this.nativePlayerTarget.volume = volume;
    if (this.audioEngine && this.nativePlayerTarget.volume > 0) this.audioEngine.volumeChanged(this.nativePlayerTarget.volume);
  }

  // Set Volume Icon
  setVolumeLevel(volume) {
    let volumeLevel = "";
    if (volume === 0) {
      volumeLevel = "muted";
    } else if (volume > 0.5) {
      volumeLevel = "high";
    } else {
      volumeLevel = "low";
    }
    this.element.dataset.volumeLevel = volumeLevel;
  }

  // Timeline Clicked
  timelineClicked(event) {
    const rect = this.timelineTarget.getBoundingClientRect();
    const percent = Math.min(Math.max(0, event.x - rect.x), rect.width) / rect.width;
    this.nativePlayerTarget.currentTime = this.audioEngine.getTimeFromTimeline(percent);
  }

  // Timeline on mouse move Update
  updateTimeline(event) {
    const rect = this.timelineTarget.getBoundingClientRect();
    let percent = Math.min(Math.max(0, event.x - rect.x), rect.width) / rect.width;
    if (percent > 0.998) percent = 1;
    this.timelineTarget.style.setProperty("--preview-position", percent);
  }

  // Event Emitters
  dispatchToggleMuteEvent(volume) {
    const event = new CustomEvent("toggle-muted", { detail: { volume: volume } });
    window.dispatchEvent(event);
  }

  // Preview time on Mouse Move
  showPreview(event) {
    const rect = this.timelineTarget.getBoundingClientRect();
    const percent = Math.min(Math.max(0, event.x - rect.x), rect.width) / rect.width;
    const time = this.audioEngine.getPreviewTimeFormatted(percent);
    this.previewTarget.textContent = time;
    this.previewTarget.style.setProperty("display", "block");
  }

  // Hide Preview time on Mouse Out
  hidePreview(event) {
    this.previewTarget.style.setProperty("display", "none");
  }

  // Utility

  displayProgressPosition(currentTime) {
    const progress = (currentTime - this.audioEngine.start_time) / (this.audioEngine.end_time - this.audioEngine.start_time);
    // value between 0 and 1
    this.timelineTarget.style.setProperty("--progress-position", progress);
  }

  // Transcription chunks loader

  expandAudioStart(event) {
    if (this.chunkEngine.start_sequence === 1) return;
    const prevChunk = this.chunkEngine.getPrevChunk();
    this.chunksTarget.insertBefore(prevChunk.node, this.chunksTarget.firstElementChild);
    this.nativePlayerTarget.currentTime = prevChunk.start_time;
    this.audioEngine.setStartTime(prevChunk.start_time);
    this.reset();
  }

  expandAudioEnd(event) {
    if (this.chunkEngine.end_sequence === 1) return;
    const chunk = this.chunkEngine.getNextChunk();
    this.chunksTarget.appendChild(chunk.node);
    this.audioEngine.setEndTime(chunk.end_time);
    this.reset();
  }

  trimAudioStart(event) {
    if (this.chunksTarget.childElementCount === 1) return;
    const chunkStartTime = this.chunkEngine.trimStart();
    this.nativePlayerTarget.currentTime = chunkStartTime;
    this.audioEngine.setStartTime(chunkStartTime);
    this.chunksTarget.firstElementChild.remove();
    this.reset();
  }

  trimAudioEnd(event) {
    if (this.chunksTarget.childElementCount === 1) return;
    const chunkEndTime = this.chunkEngine.trimEnd();
    this.audioEngine.setEndTime(chunkEndTime);
    this.chunksTarget.lastElementChild.remove();
    this.reset();
  }
}
