const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
});

function formatDuration(time) {
  const seconds = Math.floor(time % 60);
  const minutes = Math.floor(time / 60) % 60;
  const hours = Math.floor(time / 3600);
  if (hours === 0) {
    return `${minutes}:${leadingZeroFormatter.format(seconds)}`;
  } else {
    return `${hours}:${leadingZeroFormatter.format(minutes)}:${leadingZeroFormatter.format(seconds)}`;
  }
}

function convertToSeconds(time) {
  const [hours, minutes, seconds] = time.split(":").map((t) => parseInt(t));
  return hours * 3600 + minutes * 60 + seconds;
}

function convertTimeToSeconds(time) {
  const timeParts = time.split(":").map(Number);

  if (timeParts.length === 3) {
    // Format: "HH:MM:SS"
    const [hours, minutes, seconds] = timeParts;
    return hours * 3600 + minutes * 60 + seconds;
  } else if (timeParts.length === 2) {
    // Format: "MM:SS"
    const [minutes, seconds] = timeParts;
    return minutes * 60 + seconds;
  } else {
    // Invalid format, return NaN
    return NaN;
  }
}

function formatTime(recordedAtValue, currentTime) {
  const [recordedHours, recordedMinutes, recordedSeconds] = recordedAtValue.split(":").map(Number);

  const totalSeconds = Math.floor(currentTime);
  let hours = Math.floor(totalSeconds / 3600) + recordedHours;
  let minutes = Math.floor((totalSeconds % 3600) / 60) + recordedMinutes;
  const seconds = (totalSeconds % 60) + recordedSeconds;

  // Adjust hours and minutes if they exceed their ranges
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }
  if (hours >= 24) {
    hours %= 24;
  }

  // Pad single-digit hours, minutes, and seconds with leading zeros
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}H${formattedMinutes}:${formattedSeconds}`;
}

function formatDateOffset(date, offset) {
  const dateCopy = new Date(date);
  dateCopy.setSeconds(dateCopy.getSeconds() + offset);
  const time = dateCopy.getHours() + "H" + dateCopy.getMinutes() + ":" + String(dateCopy.getSeconds()).padStart(2, "0");
  return time;
}

export default { formatDuration, convertToSeconds, convertTimeToSeconds, formatTime, formatDateOffset };
