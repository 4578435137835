import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "menu", "iconClose", "iconOpen"];
  connect() {
    console.log("Toggle search Controller Connected");
  }
  toggleInput() {
    this.inputTarget.classList.toggle("hidden");
  }
  toggleMenu() {
    this.iconOpenTarget.classList.toggle("hidden");
    this.iconCloseTarget.classList.toggle("hidden");
    this.menuTarget.classList.toggle("hidden");
  }
}
