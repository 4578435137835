import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["form", "buttonDelivered", "buttonProcessing"];

  connect() {
    StimulusReflex.register(this);
  }

  deliveredSuccess() {
    this.buttonProcessingTarget.classList.remove("bg-red-400", "text-white");
    this.buttonDeliveredTarget.classList.remove("bg-white", "text-gray-700");
    this.buttonDeliveredTarget.classList.add("bg-green-400", "text-white");
    this.formTarget.reset();
  }

  processingSuccess() {
    this.buttonDeliveredTarget.classList.remove("bg-green-400", "text-white");
    this.buttonProcessingTarget.classList.remove("bg-white", "text-gray-700");
    this.buttonProcessingTarget.classList.add("bg-red-400", "text-white");
    this.formTarget.reset();
  }

  searchSuccess() {
    console.log("tototo");
    this.buttonProcessingTarget.classList.remove("bg-red-400", "text-white");
    this.buttonDeliveredTarget.classList.remove("bg-green-400", "text-white");
    this.buttonProcessingTarget.classList.add("bg-white", "text-gray-700");
    this.buttonDeliveredTarget.classList.add("bg-white", "text-gray-700");
  }
}
