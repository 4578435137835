import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    link: String,
  };

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.linkValue);
    this.alert_success();
  }

  copyHtmlTable(event) {
    event.preventDefault();
    const table_body = document.querySelector("tbody").innerText;
    navigator.clipboard.writeText(table_body);
    this.alert_success();
  }

  alert_success(event) {
    Rails.ajax({
      type: "GET",
      url: "/rtv/billing_reports/alert",
      dataType: "json",
      success: (data) => {
        document.getElementById("notification").innerHTML = data.alert;
      },
    });
  }
}
