import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["results"];

  connect() {
    console.log("Search Controller Connected");
  }

  handleResults(event) {
    const [data, status, xhr] = event.detail;
    this.resultsTarget.innerHTML = xhr.response;
    this.focus();
    this.element.scrollIntoView();
  }

  focus() {
    const autofocusedElements = document.querySelectorAll("input[autofocus]");
    if (autofocusedElements.length) {
      const length = autofocusedElements[0].value.length;
      autofocusedElements[0].focus();
      autofocusedElements[0].setSelectionRange(length, length);
    }
  }
}
