import { Controller } from "stimulus";
import("../../styles/range_input.css");

export default class extends Controller {
  static targets = ["range", "thumb", "trackInner"];
  connect() {
    this.updateSlider(this.rangeTarget.value);
  }

  updateSlider = (value) => {
    //console.log(value);
    this.thumbTarget.style.left = `${value}%`;
    this.thumbTarget.style.transform = `translate(-${value}%, -50%)`;
    this.trackInnerTarget.style.width = `${value}%`;
    this.rangeTarget.value = value;
    this.dispatchEvent();
  };

  update(event) {
    this.updateSlider(event.target.value);
  }

  dispatchEvent() {
    const event = new CustomEvent("set-volume", { detail: { volume: this.rangeTarget.value / 100 } });
    window.dispatchEvent(event);
  }

  toggleMuted(event) {
    this.updateSlider(event.detail.volume);
  }
}
