import { Controller } from "stimulus";
import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ["form", "order_button"];

  static values = {
    id: String,
  };

  connect() {
    const template = document.getElementsByTagName("template")[0];
    this.success_template = template.content.cloneNode(true);
  }

  show() {
    this.order_buttonTarget.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
  }

  hide() {
    this.order_buttonTarget.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
  }

  completed(event) {
    const { dom_id, count } = event.detail;
    if (this.idValue === dom_id) {
      this.update_cart(count);
      this.element.replaceWith(this.success_template);
    }
  }

  update_cart(nb_items) {
    const event = new CustomEvent("update-cart", { detail: { cart_count: nb_items } });
    window.dispatchEvent(event);
    console.log(nb_items);
  }
}
