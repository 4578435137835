import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["background", "dialog"];

  static values = {
    id: String,
  };

  display(event) {
    const [data, status, xhr] = event.detail;
    console.log(xhr.response);
    this.element.insertAdjacentHTML("beforeend", xhr.response);
    enter(this.dialogTarget).then(() => {});
    enter(this.backgroundTarget).then(() => {});
  }

  handleClick(event) {
    event.preventDefault();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const url = event.currentTarget.getAttribute("href");
    let responseData; // Declare a variable to store the response data

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        // Process the data as needed
        responseData = data.html;
      })
      .catch((error) => {
        console.error("Error:", error);
        // handle errors if needed
      })
      .finally(() => {
        if (responseData) {
          this.element.insertAdjacentHTML("beforeend", responseData);
        }
      });
  }

  dismiss(event) {
    event.preventDefault();
    leave(this.dialogTarget).then(() => {
      this.dialogTarget.remove();
    });
    leave(this.backgroundTarget).then(() => {
      this.backgroundTarget.remove();
    });
  }

  after_submit(event) {
    const { modal_controller_id, message } = event.detail;
    if (this.idValue === modal_controller_id) {
      this.dismiss(event);
      document.getElementById("notification").innerHTML = message;
    }
  }
}
