import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["emails"];

  connect() {
    console.log(this.emailsTarget.innerHTML);
  }

  hasChanged(event) {
    Rails.ajax({
      type: "GET",
      url: `/fr/accounts/${event.target.value}/emails`,
      dataType: "json",
      success: (response, status, xhr) => {
        this.emailsTarget.innerHTML = response.emails;
      },
      error: (response, status, xhr) => {
        console.log(response);
      },
    });
  }
}
