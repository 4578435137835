import formatter from "../../../helpers/formatter";
export default class AudioEngine {
  constructor(recorded_at, result) {
    this.recorded_at = recorded_at;
    this.currentTime = result.start_chunk.start_time;
    this.start_time = result.start_chunk.start_time;
    this.end_time = result.end_chunk.end_time;
    this.volume = 1;
    this.formatCurrentTime = formatter.formatDateOffset(this.recorded_at, this.currentTime);
    this.formatStartTime = formatter.formatDateOffset(this.recorded_at, this.currentTime);
    this.formatEndTime = formatter.formatDateOffset(this.recorded_at, this.end_time);
  }

  setStartTime(time) {
    this.start_time = time;
  }

  getStartTime() {
    return this.start_time;
  }

  getCurrentTime() {
    return this.currentTime;
  }

  setEndTime(time) {
    this.end_time = time;
  }

  getEndTime() {
    return this.end_time;
  }

  getCurrentTimeFormatted() {
    return this.formatCurrentTime;
  }

  getPreviewTimeFormatted(percent) {
    const calculatedTime = percent * (this.end_time - this.start_time) + this.start_time;
    return formatter.formatDateOffset(this.recorded_at, parseInt(calculatedTime));
  }

  getTimeFromTimeline(percent) {
    return percent * parseInt(this.end_time - this.start_time) + this.start_time;
  }

  getVolume() {
    return this.volume;
  }

  volumeChanged(volume) {
    this.volume = volume;
  }

  timeUpdate(time) {
    this.currentTime = time;
    this.formatCurrentTime = formatter.formatDateOffset(this.recorded_at, time);
    console.log(this);
  }
}
