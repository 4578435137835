import Rails from "@rails/ujs";
// app/javascript/controllers/list_filter_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["results", "loadingSpinner"];

  filterCliked(event) {
    // Show the loading spinner when the AJAX request starts
    this.loadingSpinnerTarget.classList.remove("hidden");
    this.resultsTarget.classList.remove("opacity-100");
    this.resultsTarget.classList.add("opacity-0");

    const buttons = this.element.querySelectorAll("button");
    const clickedButton = event.currentTarget;
    const color = clickedButton.dataset.color;
    const url = clickedButton.dataset.url;

    buttons.forEach((button) => {
      const isClickedButton = button.dataset.id === clickedButton.dataset.id;
      const bgColorClass = isClickedButton ? `bg-${color}` : "bg-white";
      const textColorClass = isClickedButton ? "text-white" : "text-gray-900";

      button.classList.remove(`bg-${button.dataset.color}`, "text-white", "bg-white", "text-gray-900");
      button.classList.add(bgColorClass, textColorClass);
    });

    // Make an AJAX call using Rails.ajax
    Rails.ajax({
      url: url + `?data_id=${clickedButton.dataset.id}`,
      type: "get", // You can change the HTTP method if needed
      dataType: "json", // Add this line to specify the response format as JSON
      success: (data) => {
        setTimeout(() => {
          // Hide the loading spinner after the delay
          this.loadingSpinnerTarget.classList.add("hidden");

          // Handle the AJAX response here if needed
          this.resultsTarget.innerHTML = data.html;
          this.resultsTarget.classList.remove("opacity-0");
          this.resultsTarget.classList.add("opacity-100");
        }, 500);
      },
      error: (error) => {
        // Handle AJAX error here if needed
        console.error(error);
      },
    });
  }
}
