import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["item", "iconPlus", "iconClose"];
  static values = {
    url: String,
  };

  connect() {
    console.log("Order Management Controller");
  }

  toggle() {
    this.itemTarget.classList.toggle("hidden");
    // this.toggleIcon();
    if (this.itemTarget.innerHTML.trim().indexOf("Loading") !== -1) {
      this.load_line_items();
    }
  }

  load_line_items() {
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      dataType: "json",
      success: (data) => {
        this.itemTarget.innerHTML = data.content;
      },
    });
  }

  refresh_line_items() {}

  toggleIcon() {
    if (this.iconPlusTarget.classList.contains("hidden")) {
      this.iconPlusTarget.classList.remove("hidden");
      this.iconCloseTarget.classList.add("hidden");
    } else {
      this.iconPlusTarget.classList.add("hidden");
      this.iconCloseTarget.classList.remove("hidden");
    }
  }
}
